jQuery(document).ready(function($) {
  require('./components/mobile-header.js')
  require('./components/accordion.js')
  require('./components/video.js')
  require('./components/slider.js')

  loadMorePosts.init();
  loadMoreProjects.init();

  // Animated Section
  var animatedSections = jQuery('.animated-section');
  var windowHeight = window.innerHeight;

  if (animatedSections.length > 0) {

      animatedSections.each(function () {
          var section = jQuery(this);
          var offsetTop = section.offset().top;

          if (window.pageYOffset >= offsetTop - windowHeight / 1.5) {
              jQuery(section).addClass('active');
          }
      });

      window.addEventListener('scroll', function () {

          animatedSections.each(function () {
              var section = jQuery(this);
              var offsetTop = section.offset().top;

              console.log(offsetTop)
              console.log(window.pageYOffset)

              if (window.pageYOffset >= offsetTop - windowHeight / 1.5) {
                jQuery(section).addClass('active');
              }
          });
      });
  }
});


// Load More Posts

var loadMorePosts = {
	init: function() {
			jQuery('#load-more').click(function(){
          loadMorePosts.loadMore();
			});
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-container');
		var postCount = jQuery('#load-container .archive-single-post').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
				action: 'load_more_posts',
				post_count: postCount,
				post_search: posts.s,
				current_taxonomy: posts.current_taxonomy,
				current_term: posts.current_term,
				current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
						loadContainer.append(data);
				}

				postCount = jQuery('#load-container .archive-single-post').length;

				if(postCount >= totalPosts) {
						jQuery('#load-more').replaceWith('<a class="no-posts" href="#load-container">Back to top</a>');
				}

				return;
			}
		});
	}
}


var loadMoreProjects = {
	init: function() {
    jQuery('#load-more2').click(function(){
      loadMoreProjects.loadMore();
    });
	},

	loadMore: function() {
		var loadContainer = jQuery('#load-container2');
		var postCount = jQuery('#load-container2 .archive-single-project').length;
		var totalPosts = loadContainer.data('total');

		if(postCount >= totalPosts) {
			return;
		}

		var data = {
      action: 'load_more_projects',
      post_count: postCount,
      post_search: posts.s,
      current_taxonomy: posts.current_taxonomy,
      current_term: posts.current_term,
      current_child_term: posts.current_child_term,
		};

		jQuery.ajax({
			url: posts.ajaxurl,
			type: "POST",
			data: data,
			beforeSend: function () {

				// Request for posts

				jQuery('#load-more2').addClass('loading');
			},
			complete: function () {

				// Request for posts received / response is being sent back to us

				jQuery('#load-more2').removeClass('loading');
			},
			success: function(data) {

				// We get posts back / successful response

				if(data) {
					loadContainer.append(data);
				}

				postCount = jQuery('#load-container2 .archive-single-project').length;

				if(postCount >= totalPosts) {
					jQuery('#load-more2').replaceWith('<a class="no-posts" href="#load-container2">Back to top</a>');
				}

				return;
			}
		});
	}
}